export const allUserRoles = [
  "Admin",
  "Department Manager",
  "Client",
  "Supplier Admin",
  "Supplier User",
  "Delivery",
  "Department User",
  "Customer Admin"
]

export const allUserRolesExceptAdmin = [
  "Department Manager",
  "Client",
  "Supplier Admin",
  "Supplier User",
  "Delivery",
  "Department User",
  "Customer Admin"
]

export const customerUserRoles = [
  "Customer Admin",
  "Department Manager",
  "Client",
  "Department User"
]

export const pushUserRoles = [
  "Customer Admin",
  "Department Manager",
  "Client",
  "Department User"
]

export const pushUserRolesMapping = {
  "Customer Admin": 7,
  "Department Manager": 1,
  "Client": 2,
  "Department User": 6
}

export const supplierUserRoles = ["Supplier Admin", "Supplier User"]

export function getRoleID(role) {
  let roleValue = role.toUpperCase().replace(/ /g, "_")
  return `USER_ROLES.${roleValue}`
}
