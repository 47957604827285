/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import SupplierLocationRangeFilterPanel from "../modules/Reports/SupplierLocationRangeFilterPanel"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import {
  getLocations,
  getSupplier,
  getSuppliers
} from "../modules/Suppliers/_axios/supplierCrud"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import "moment/locale/he"
import { getMealTypeText } from "../modules/Common/mealTypesTranslation"
import { getSupplierReports } from "../modules/Reports/_axios/reportsCrud"
import Paper from "@material-ui/core/Paper"
import SupplierMealsList from "../modules/Reports/SupplierReports/SupplierMealsList"
import SupplierMealsSummaryList from "../modules/Reports/SupplierReports/SupplierMealsSummaryList"
import Typography from "@material-ui/core/Typography"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"

function SupplierReportsPage({ userRole, ownSupplierID, intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [supplierData, setSupplierData] = useState({
    data: [],
    isLoading: true
  })
  const [locationsData, setLocationsData] = useState({
    data: [],
    isLoading: true,
    selectedSupplierID: null,
    notRequested: true
  })

  const isUserAdmin = userRole === "Admin"

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }

  function processReports(data) {
    return data.sort((a, b) => new Date(a.Date) - new Date(b.Date))
  }

  function fetchReports(cancelToken) {
    const { supplier, location, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      location,
      supplier: supplier.SupplierID
    }

    props.history.push({
      pathname: "/reports-supplier",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getSupplierReports(
      supplier.SupplierID,
      location || null,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: processReports(data),
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchSuppliers(cancelToken) {
    setSupplierData({ ...supplierData, isLoading: true })

    if (isUserAdmin) {
      // Request for Admin
      getSuppliers(cancelToken.token)
        .then(({ data }) => {
          setSupplierData({
            data: data,
            isLoading: false
          })
        })
        .catch(error =>
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
            })
          )
        )
    } else {
      // Request for Supplier Admin
      getSupplier(cancelToken.token, ownSupplierID)
        .then(({ data }) => {
          setSupplierData({
            data: [data],
            isLoading: false
          })
        })
        .catch(error =>
          handleApiError(
            dispatch,
            error,
            intl.formatMessage({
              id: "API.ERROR.FAILED_TO_GET_SUPPLIER"
            })
          )
        )
    }
  }

  function fetchLocations(cancelToken) {
    setLocationsData({ ...locationsData, isLoading: true })
    getLocations(locationsData.selectedSupplierID, false, cancelToken.token)
      .then(({ data }) => {
        setLocationsData({
          data: data,
          isLoading: false,
          selectedSupplierID: locationsData.selectedSupplierID,
          notRequested: false
        })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
          })
        )
      )
  }

  function handleUpdateLocations(supplierID) {
    setLocationsData({
      ...locationsData,
      selectedSupplierID: supplierID
    })
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!supplierData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const location = urlParams.get("location") || ""
      const supplier = isUserAdmin
        ? urlParams.get("supplier")
          ? supplierData.data.find(
              supplier => supplier.SupplierID === urlParams.get("supplier")
            )
          : {}
        : supplierData.data[0] || {}

      setSearchParams({
        from,
        to,
        location,
        supplier
      })
    }
  }, [supplierData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.to &&
      searchParams.supplier &&
      searchParams.supplier.SupplierID &&
      !locationsData.notRequested
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      const locationName = searchParams.location
        ? intl.formatMessage({
            id: "EXPORT_FILE_LOCATION"
          }) +
          "_" +
          locationsData.data.find(
            location => location.LocationID === searchParams.location
          ).Name +
          "_"
        : ""

      setExportFileName(
        intl.formatMessage({
          id: "REPORT.EXPORT_FILE_NAME"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_SUPPLIER"
          }) +
          "_" +
          searchParams.supplier.Name +
          "_" +
          locationName +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams, locationsData.notRequested])

  useEffect(() => {
    if (locationsData.selectedSupplierID) {
      const cancelToken = axios.CancelToken.source()
      fetchLocations(cancelToken)
      return () => cancelToken.cancel()
    }
  }, [locationsData.selectedSupplierID])

  function getExportData() {
    const quantity = reportsData.reports.reduce((sum, i) => sum += i.Meals.reduce((s, t) => s + t.Quantity, 0), 0)
    const refoundQuantity = reportsData.reports.reduce((sum, i) => sum += i.Meals.reduce((s, t) => s + t.RefoundQuantity, 0), 0)
    const netQuantity = reportsData.reports.reduce((sum, i) => sum += i.Meals.reduce(( s, t) => s + t.NetQuantity, 0), 0)
    const totalCost = reportsData.reports.reduce((sum, i) => sum += i.Meals.reduce((s, t) => s + t.TotalCost, 0), 0)
    const formattedReport = []
    reportsData.reports.forEach(report => {
      report.Meals.forEach(meal => {
        const formattedMeal = {}
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.DATE"
          })
        ] = moment(report.Date)
          .locale("he")
          .format("DD/MM/YYYY dddd")
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.MEAL_TYPE"
          })
        ] = getMealTypeText(intl, meal.MealType)
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.QUANTITY"
          })
        ] = meal.Quantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.REFOUND_QUANTITY"
          })
        ] = meal.RefoundQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.NET_QUANTITY"
          })
        ] = meal.NetQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.TOTAL_COST"
          })
        ] = meal.TotalCost
        formattedReport.push(formattedMeal)
      })
    })



    const formattedMeal = {}
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.DATE"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.QUANTITY"
        })
        ] = quantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.REFOUND_QUANTITY"
        })
        ] = refoundQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.NET_QUANTITY"
        })
        ] = netQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.TOTAL_COST"
        })
        ] = totalCost
    formattedReport.push(formattedMeal)


    return formattedReport
  }

  return (
    <>
      {searchParams.supplier && (
        <SupplierLocationRangeFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          supplierData={supplierData}
          locationsData={locationsData}
          initialSearchParams={searchParams}
          updateLocations={handleUpdateLocations}
          hideSupplierField={!isUserAdmin}
        />
      )}
      {
        reportsData && reportsData.reports && reportsData.reports.length > 0 && (
            <SupplierMealsSummaryList mealsData={reportsData} searchParams={searchParams}/>
        )
      }
      <ListsWrapper
        reportsData={reportsData}
        contents={reportsData.reports.map(day => (
          <Paper className={classes.paper} key={day.Date}>
            <Typography className={classes.heading}>
              {moment(day.Date)
                .locale("he")
                .format("DD/MM/YYYY dddd")}
            </Typography>
            <SupplierMealsList mealsData={day} searchParams={searchParams} />
          </Paper>
        ))}
        exportData={getExportData()}
        exportFileName={exportFileName}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    ownSupplierID: state.auth.user.SupplierID,
    userRole: state.auth.user.Role
  }
}
export default withRouter(
  injectIntl(
    connect(mapStateToProps, snackbarRedux.actions)(SupplierReportsPage)
  )
)
