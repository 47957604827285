import TableCell from "@material-ui/core/TableCell";
import {
    Button,
    Checkbox,
    Collapse,
    FilledInput,
    FormControl,
    IconButton,
    makeStyles,
    MenuItem,
    Select, TextField,
    Tooltip
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import TableRow from "@material-ui/core/TableRow";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import { formatDate } from "../Common/momentFunctions"
import DishLoader from "../Common/DishLoader"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"
import { getCombosOptions } from "./_axios/comboCrud"
import axios from "axios";

const useStyles = makeStyles(theme => ({
    textField: {
        margin: 0,
        minWidth: 110
    },
    optionGroupField: {
        margin: 0,
        minWidth: 90
    },
    priorityField: {
        margin: 0,
        width: 80
    },
    input: {
        padding: theme.spacing(1)
    },
    divider: {
        margin: theme.spacing(0, 1)
    },
    iconDisplay: {
        maxHeight: 100,
        maxWidth: 174,
        width: "100%"
    },
    collapsibleRow: {
        "& > *": {
            borderBottom: "unset"
        }
    },
    formControl: {
        width: '120px'
    },
    imgContainer: {
        width: '120px'
    }
}))

function ComboItem({
                       comboId,
                       item,
                       isSelected,
                       onSelect,
                       onItemFieldChange,
                       setSelectedItems,
                       selectedItems,
                       setDateRangeDialogState,
                       setSelectedItemOptions,
                       intl
                   }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [comboOptionsData, setComboOptionsData] = useState({
        data: [],
        isLoading: false
    })

    useEffect(
        () => {
            setSelectedItemOptions(comboOptionsData?.data)
        }, [comboOptionsData?.data]
    )

    useEffect(() => {
        if (open)
            if (item?.Id && comboId) {
                const cancelToken = axios.CancelToken.source()
                fetchCombosOptions(item, comboId, cancelToken)
                return () => cancelToken.cancel()
            } else {
                setComboOptionsData({
                    data: item.Item.OptionGroups.map(optionGroup => {
                        return {
                            optionGroupId: optionGroup.id,
                            Label: optionGroup.Lable,
                            Type: optionGroup.IsFree ? 1 : 2,
                            Order: optionGroup.Order,
                            ComboItemId: item.id
                        }
                    }),
                    isLoading: false
                })
            }
    }, [open])

    function fetchCombosOptions(comboItem, id, cancelToken) {
        setComboOptionsData({ ...comboOptionsData, isLoading: true })
        getCombosOptions(id, comboItem.Id, cancelToken.token)
            .then(({ data }) => {
                setComboOptionsData({
                    data: item.Item.OptionGroups.map(optionGroup => {
                        const comboOption = data.find(el => optionGroup.id === el.OptionGroupId)
                        return (
                            comboOption || {
                                optionGroupId: optionGroup.id,
                                Label: optionGroup.Lable,
                                Type: optionGroup.IsFree ? 1 : 2,
                                Order: optionGroup.Order,
                                ComboItemId: item.id
                            }
                        )
                    }),
                    isLoading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_COMBO_OPTIONS"
                    })
                )
            )
    }

    function handleChangeOptionGroupType(event, label) {
        const value = event.target.value
        const changedOptionGroups = [...comboOptionsData.data]
        const changedOptionGroup = changedOptionGroups.find(
            optionGroup => optionGroup.Label === label
        )
        changedOptionGroup.Type = value
        setComboOptionsData({ ...comboOptionsData, data: changedOptionGroups })
    }

    return <React.Fragment>
        <TableRow>
            <TableCell>
                <Tooltip
                    title={intl.formatMessage({
                        id: "COMBO_DIALOG.SEE_OPTION_GROUPS.TOOLTIP"
                    })}
                >
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell>
                {item.Name}
            </TableCell>
            <TableCell>
                {item.CustomerPrice}
            </TableCell>
            <TableCell>
                {item.ClientPrice}
            </TableCell>
            <TableCell>
                {item.SupplierName}
            </TableCell>
            <TableCell>
                <img src={item.ImageUrl} className={classes.imgContainer}/>
            </TableCell>
            <TableCell>
                <Checkbox
                    name="active"
                    checked={selectedItems[item.ItemId]}
                    color="secondary"
                    onChange={(event, i) => {
                        const otemObj = {
                            ...selectedItems.data,
                        };
                        otemObj[item.ItemId] = !otemObj[item.ItemId];
                        setSelectedItems({
                            data: otemObj,
                            count: Object.values(otemObj).filter(t => t).length
                        });
                    }}
                />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className="position-relative" style={{ minHeight: 30 }}>
                        {comboOptionsData.isLoading ? (
                            <DishLoader height={30} minHeight={30} />
                        ) : (
                            comboOptionsData.data.length !== 0 && (
                                <div className="row">
                                    <div className="col-5">
                                        {comboOptionsData.data.map(optionGroup => (
                                            <div className="row" key={optionGroup.Label}>
                                                <div className="col-8 d-flex justify-content-end align-items-center pr-0 text-right">
                                                    {optionGroup.Label}
                                                </div>
                                                <div className="col-4 pl-3 pt-3 pb-3">
                                                    <FormControl
                                                        variant="filled"
                                                        className={classes.optionGroupField}
                                                        fullWidth
                                                    >
                                                        <Select
                                                            value={optionGroup.Type}
                                                            onChange={event =>
                                                                handleChangeOptionGroupType(
                                                                    event,
                                                                    optionGroup.Label
                                                                )
                                                            }
                                                            input={
                                                                <FilledInput
                                                                    name="optionGroupStatus"
                                                                    inputProps={{
                                                                        className: classes.input
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <MenuItem value={0}>
                                                                <FormattedMessage id="COMBO_ITEMS.OPTION_GROUP.TYPE.HIDDEN" />
                                                            </MenuItem>
                                                            <MenuItem value={1}>
                                                                <FormattedMessage id="COMBO_ITEMS.OPTION_GROUP.TYPE.FREE" />
                                                            </MenuItem>
                                                            <MenuItem value={2}>
                                                                <FormattedMessage id="COMBO_ITEMS.OPTION_GROUP.TYPE.PAID" />
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-5 d-flex flex-column justify-content-center align-items-center">
                                        {/*<FormattedMessage id="COMBO_ITEM.LABELS.AVAILABILITY" />
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                            <TextField
                                                placeholder={intl.formatMessage({
                                                    id: "DATE_FIELD.FROM"
                                                })}
                                                className={classes.textField}
                                                inputProps={{
                                                    className: classes.input,
                                                    readOnly: true
                                                }}
                                                margin="normal"
                                                variant="filled"
                                                fullWidth
                                                value={item.From ? formatDate(item.From) : ""}
                                                onClick={() =>
                                                    setDateRangeDialogState({
                                                        show: true,
                                                        comboItemID: item.Item.ItemID,
                                                        maxDate: item.Item.AvilableTo
                                                            ? new Date(`${item.Item.AvilableTo}.000Z`)
                                                            : null,
                                                        minDate: item.Item.AvilableFrom
                                                            ? new Date(`${item.Item.AvilableFrom}.000Z`)
                                                            : null,
                                                        from: item.From,
                                                        to: item.To
                                                    })
                                                }
                                            />
                                            <div className={classes.divider}>-</div>
                                            <TextField
                                                placeholder={intl.formatMessage({
                                                    id: "DATE_FIELD.TO"
                                                })}
                                                className={classes.textField}
                                                inputProps={{
                                                    className: classes.input,
                                                    readOnly: true
                                                }}
                                                margin="normal"
                                                variant="filled"
                                                fullWidth
                                                value={item.To ? formatDate(item.To) : ""}
                                                onClick={() =>
                                                    setDateRangeDialogState({
                                                        show: true,
                                                        comboItemID: item.Item.ItemID,
                                                        maxDate: item.Item.AvilableTo
                                                            ? new Date(`${item.Item.AvilableTo}.000Z`)
                                                            : null,
                                                        minDate: item.Item.AvilableFrom
                                                            ? new Date(`${item.Item.AvilableFrom}.000Z`)
                                                            : null,
                                                        from: item.From,
                                                        to: item.To
                                                    })
                                                }
                                            />
                                        </div>*/}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>

}
export default injectIntl(ComboItem)
