/* eslint-disable no-restricted-imports */

import axios from "axios"
import React, {useEffect, useState} from "react"
import DishLoader from "../../app/modules/Common/DishLoader"
import LocationList from "../../app/modules/Push/LocationList"
import ConfirmationQuestionDialog from "../../app/modules/Push/ConfirmationQuestionDialog"
import {getMealTypeText} from "../modules/Common/mealTypesTranslation"
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers"
import {formatDate} from "../../app/modules/Common/momentFunctions"
import {useHistory} from "react-router-dom";
import {useFormik} from "formik"
import {
    getItems,
    getItemLocations,
    getCustomerLocations,
    calculateLocationUsers,
    getLocations,
    getCustomersList,
    sendPushToUsers,
    sendPushToUsersSingle
} from "../modules/Customers/_axios/customerCrud"
import {
    getSuppliers
} from "../modules/Suppliers/_axios/supplierCrud"
import {FormattedMessage, injectIntl} from "react-intl"
import {
    FilledInput,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
    Button,
    InputAdornment,
    IconButton, Checkbox
} from "@material-ui/core"
import {withRouter} from "react-router-dom"
import {
    pushUserRoles,
    getRoleID,
    pushUserRolesMapping
} from "../modules/Users/rolesTranslation"
import {useSelector, useDispatch} from "react-redux"
import {handleApiError, handleApiSuccess} from "../../redux/snackbar/snackbarHandlers"
import * as Yup from "yup";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {he} from "date-fns/esm/locale";
import {DatePicker} from "@material-ui/pickers"
import Picker from 'emoji-picker-react';
import {EmojiEmotions, TagFaces} from "@material-ui/icons"
import {formatDateForApi} from "../modules/Common/momentFunctions"

const useStyles = makeStyles(theme => ({
        root: {
            minHeight: 591
        },
        textField: {
            marginTop: 16
        },
        timePicker: {
            display: 'flex',
            alignItems: 'center'
        },
        timePickerField: {
            marginTop: 8
        },
        totalItems: {
            display: 'flex',
            alignItems: 'center'
        },
        pushImage: {
            width: '50%',
            minWidth: 250,
            maxWidth: 560,
            margin: 16
        },
        optionsPanel: {
            marginTop: 8,
            marginBottom: 8
        },
        pushMessageTitle: {
            marginTop: 8,
            marginBottom: 8
        },
        optionBtn: {
            marginRight: 6
        },
        emojiPicker: {
            position: "absolute",
            zIndex: 2,
            top: "100%"
        }
    })
);

const sendTypes = [
    {
        id: 1,
        name: 'עכשיו'
    },
    {
        id: 2,
        name: 'זמן עתידי'
    },
]

const ordereds = [
    {
        id: 1,
        name: 'Delivered'
    },
    {
        id: 2,
        name: 'Not delivered'
    },
]

const actionTypes = [
    {
        id: 1,
        name: 'ללא'
    },
    {
        id: 2,
        name: 'URL'
    },
    {
        id: 3,
        name: 'תמונה'
    }
];


const pushTypes = [
    {
        id: 1,
        name: 'כללי'
    },
    {
        id: 2,
        name: 'פריט'
    },
    {
        id: 3,
        name: 'Supplier'
    }
];


function PushAdminPage({intl, ...props}) {

    const todayLabel = new Date();
    const today = (todayLabel.getMonth() + 1) + '/' +
        todayLabel.getDate() + '/' +
        todayLabel.getFullYear();

    const history = useHistory();
    const dispatch = useDispatch()
    const classes = useStyles()
    const roles = pushUserRoles

    const userRole = useSelector(state => state.auth.user.Role)
    const [selectedUser, setSelectedUser] = useState({UserID: ""})
    const [pushImage, setPushImage] = useState({PushImage: ""})
    const [globalPushImage, setGlobalPushImage] = useState({PushImage: ""})

    const [showEmojiPickerOnField, setShowEmojiPickerOnField] = useState("")

    const meals = [
        {
            id: 1,
            name: getMealTypeText(intl, 1),
        },
        {
            id: 2,
            name: getMealTypeText(intl, 2),
        },
        {
            id: 3,
            name: getMealTypeText(intl, 3),
        },
        {
            id: 4,
            name: getMealTypeText(intl, 4),
        }
    ]

    const [locationData, setLocationData] = useState({
        locations: [],
        selectedCount: 0,
        loading: false
    })

    const [customerData, setCustomerData] = useState({
        customers: [],
        loading: false
    })

    const [departmentData, setDepartmentData] = useState({
        departments: [],
        loading: false
    })

    const [isSelectedLocations, setIsSelectedLocations] = useState({
        selectedAll: false,
    })

    const [supplierData, setSupplierData] = useState({
        suppliers: [],
        loading: true
    })

    const [countData, setCountData] = useState({
        count: 0,
        loading: false
    })

    const [itemData, setItemData] = useState({
        items: [],
        loading: false
    })

    const [confirmationQuestionState, setConfirmationQuestionState] = useState({
        show: false
    })

    const isUserAdmin = userRole === "Admin"

    const initialValues = {
        pushType: 1,
        supplier: '',
        customer: '',
        location: '',
        item: '',
        mealType: 1,
        actionType: 1,
        message: '',
        title: '',
        subTitle: '',
        role: '',
        sendType: 1,
        sendTime: '',
        sendDate: formatDate(
            new Date(
                todayLabel.getDate() + '/' +
                (todayLabel.getMonth() + 1) + '/' +
                todayLabel.getFullYear()
            )
        ),
        delivery: false,
        orderDate: formatDate(
            new Date(
                todayLabel.getDate() + '/' +
                (todayLabel.getMonth() + 1) + '/' +
                todayLabel.getFullYear()
            )
        ),
        image: ''
    };

    const [selectedPushType, setPushType] = useState({PushType: 1})

    function checkForError(fieldName) {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true
        }
        return false
    }

    function renderErrors(fieldName) {
        return checkForError(fieldName) ? (
            <span style={{color: "#F018A6"}}>{formik.errors[fieldName]}</span>
        ) : null
    }

    function sortLocations(locations) {
        return locations.sort((a, b) => a.Name.localeCompare(b.Name));
    }

    function fetchFullListCustomers(cancelToken) {
        setCustomerData(
            {
                customers: [],
                loading: true
            }
        )
        getCustomersList(cancelToken.token, true)
            .then(({data}) => {
                setCustomerData({
                    customers: data,
                    loading: false
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_CUSTOMERS"
                    })
                )
            )
    }

    function fetchFullListLocations(cancelToken) {
        getLocations(cancelToken.token)
            .then(({data}) => {
                const defaultLocations = setLocationsAsSelectedByDefault(data);
                setLocationData({
                    locations: sortLocations(defaultLocations),
                    selectedCount: defaultLocations.length,
                    loading: false
                })
                setIsSelectedLocations({
                    selectedAll: defaultLocations.every(i => i.IsSelected)
                });
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function setLocationsAsSelectedByDefault(data) {
        return data.map(item => {
            return {...item, IsSelected: true}
        })
    }

    function fetchCustomerLocations(customerID, cancelToken) {
        setLocationData({locations: [], selectedCount: 0, loading: true})
        setIsSelectedLocations({
            selectedAll: false
        });
        getCustomerLocations(customerID, cancelToken.token)
            .then(({data}) => {
                const defaultLocations = setLocationsAsSelectedByDefault(data);
                setLocationData({
                    locations: sortLocations(defaultLocations),
                    selectedCount: defaultLocations.length,
                    loading: false
                })
                setIsSelectedLocations({
                    selectedAll: true
                });
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_LOCATIONS"
                    })
                )
            )
    }

    function fetchMealAndItemLocations(itemID, cancelToken) {
        getItemLocations(formik.values.mealType, itemID, cancelToken.token)
            .then(({data}) => {
                const defaultLocations = setLocationsAsSelectedByDefault(data);
                setLocationData({
                    locations: sortLocations(defaultLocations),
                    selectedCount: defaultLocations.length,
                    loading: false
                })
                setIsSelectedLocations({
                    selectedAll: defaultLocations.every(i => i.IsSelected)
                });
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function fetchItems(supplierId, cancelToken) {
        getItems(supplierId, cancelToken.token)
            .then(({data}) => {
                setItemData({items: data, loading: false})
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    function getData() {

        const data = new FormData()
        if (formik.values.image) {
            data.append("image", formik.values.image)
        }
        if (formik.values.pushImage) {
            data.append("pushImage", formik.values.pushImage)
        }
        data.append("mealType", formik.values.mealType)
        if (formik.values.name) {
            data.append("name", formik.values.name)
        }
        if (formik.values.title) {
            data.append("title", formik.values.title)
        }
        if (formik.values.subTitle) {
            data.append("subTitle", formik.values.subTitle)
        }
        if (formik.values.message) {
            data.append("message", formik.values.message)
        }
        if (formik.values.url) {
            data.append("url", formik.values.url)
        }
        if (formik.values.actionType) {
            data.append("actionType", formik.values.actionType)
        }
        if (selectedPushType.PushType) {
            data.append("type", selectedPushType.PushType)
        }
        if (formik.values.item) {
            data.append("itemID", formik.values.item)
        }
        if (formik.values.role) {
            data.append("role", pushUserRolesMapping[formik.values.role])
        }
        if (formik.values.sendType) {
            data.append("sendType", formik.values.sendType)
        }
        console.log(formik.values)
        if (formik.values.supplier) {
            data.append("supplierID", formik.values.supplier)
        }
        if (formik.values.sendType == 2) {
            if (formik.values.sendDate && typeof formik.values.sendDate === 'object') {
                data.append("sendDate", formik.values.sendDate.toISOString())
            } else {
                data.append("sendDate", new Date().toISOString())
            }
            if (formik.values.sendTime && typeof formik.values.sendTime === 'object') {
                data.append("sendTime", formik.values.sendTime.toISOString().substring(11, 16))
            } else {
                data.append("sendTime", new Date().toISOString().substring(11, 16))
            }
        }

        if (selectedPushType.PushType == 1) {
            if (formik.values.customer) {
                data.append("customerID", formik.values.customer)
            }

            if (formik.values.department) {
                data.append("departmentID", formik.values.department)
            }

            if (formik.values.delivery) {
                data.append("ordered", formik.values.ordered)
                if (formik.values.orderDate && typeof formik.values.orderDate === 'object') {
                    data.append("orderDate", formik.values.orderDate.toISOString())
                } else {
                    data.append("orderDate", new Date().toISOString())
                }
            }
        }

        return data;
    }

    function handleTestSubmit() {
        const cancelToken = axios.CancelToken.source()
        const data = getData();
        data.append("user", formik.values.source)

        sendPushToUsersSingle(data, cancelToken.token)
            .then(({data}) => {
                if (data.Message) {
                    handleApiError(
                        dispatch,
                        {
                            response: {
                                status: 400
                            }
                        },
                    intl.formatMessage({
                        id: "API.ERROR.INCORRECT_USER"
                    })
                )
                } else {
                    handleApiSuccess(dispatch, intl.formatMessage({
                        id: "API.SUCCESS"
                    }));
                }
            })
            .catch(error => {
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.INCORRECT_REQUEST"
                    })
                )
            })
        return () => cancelToken.cancel()
    }

    function handleSendConfirmationSubmit(event) {
        const cancelToken = axios.CancelToken.source()
        const locationIds = locationData.locations.filter(item => item.IsSelected)
            .map(item => item.LocationID);
        calculateLocationUsers(locationIds,
            formik.values.mealType,
            formik.values.role,
            formik.values.customer,
            formik.values.department,
            formik.getFieldProps('delivery')?.value ? formik.values.ordered : undefined,
            formik.getFieldProps('delivery')?.value ? formatDateForApi(formik.values.orderDate) : undefined,
            cancelToken.token)
            .then(({data}) => {
                setCountData({sendCount: data, loading: false})
                setConfirmationQuestionState({
                    show: true
                })
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED"
                    })
                )
            )

    }

    function handleCloseConfirmationQuestionPanel(event) {
        setConfirmationQuestionState({show: false})
    }

    function handleSendSubmit(event) {
        const cancelToken = axios.CancelToken.source()
        const locationIds = locationData.locations.filter(item => item.IsSelected)
            .map(item => item.LocationID);
        const data = getData();
        locationIds.forEach((item, index) => {
            data.append(`locationIds[${index}]`, item);
        });
        var unSelected = locationData.locations.filter(item => !item.IsSelected).length;
        if(unSelected==0){
            data.append('AllLocations', true);
        }
        return sendPushToUsers(data, cancelToken.token)
    }

    function handleCalculationSubmit(event) {
        setCountData({count: 0, loading: true})
        const cancelToken = axios.CancelToken.source()
        const locationIds = locationData.locations.filter(item => item.IsSelected)
            .map(item => item.LocationID);
        calculateLocationUsers(
            locationIds,
            formik.values.mealType,
            formik.values.role,
            formik.values.customer,
            formik.values.department,
            formik.getFieldProps('delivery')?.value ? formik.values.ordered : undefined,
            formik.getFieldProps('delivery')?.value ? formatDateForApi(formik.values.orderDate) : undefined,
            cancelToken.token)
            .then(({data}) => {
                setCountData({count: data, loading: false})
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED"
                    })
                )
            )
        return () => cancelToken.cancel()
    }

    function fetchSuppliers(cancelToken) {
        getSuppliers(cancelToken.token)
            .then(({data}) => {
                setSupplierData({suppliers: data, isLoading: false})
            })
            .catch(error =>
                handleApiError(
                    dispatch,
                    error,
                    intl.formatMessage({
                        id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
                    })
                )
            )
    }

    const validationSchema = Yup.object().shape({})

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting, setFieldError, resetForm}) => {
            const {
                pushType,
                supplier,
                location,
                role,
                customer,
                department,
                item,
                actionType,
                sendTime,
                sendDate,
                orderDate,
                image,
                message,
                name,
                title, subTitle,
                url,
                sendType
            } = values
        }
    })

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        fetchSuppliers(cancelToken)
        locationDataUpdate(selectedPushType.PushType);
        return () => cancelToken.cancel()
    }, [])

    function locationDataUpdate(pushType) {
        const cancelToken = axios.CancelToken.source()
        setLocationData({locations: [], selectedCount: 0, loading: true})
        setIsSelectedLocations({
            selectedAll: false
        });
        setCountData({sendCount: 0, loading: false});
        if (pushType !== 1 && (formik.values.supplier && formik.values.item)) {
            fetchMealAndItemLocations(formik.values.item, cancelToken)
        } else {
            fetchFullListLocations(cancelToken)
            fetchFullListCustomers(cancelToken)
        }
        return () => cancelToken.cancel()
    }

    function handleLocationSelection() {
        const isSelected = locationData.locations.some(item => item.IsSelected);
        locationData.locations.forEach(item => {
            item.IsSelected = !isSelected;
        })
        setLocationData({
            locations: sortLocations(locationData.locations),
            selectedCount: locationData.locations.filter(item => item.IsSelected).length,
            loading: false
        })
        setIsSelectedLocations({
            selectedAll: locationData.locations.every(i => i.IsSelected)
        });
        setCountData({sendCount: 0, loading: false});
    }

    function defaultImageSet(itemObj) {
        const filteredItem =
            itemData.items
                .filter(item => item.ItemID === itemObj);
        if (filteredItem[0]) {
            const itemImage =
                filteredItem[0].Image.filter(it => it.Type === 'jpg');
            if (itemImage[0]) {
                setPushImage({PushImage: itemImage[0].URL});
            } else {
                setPushImage({PushImage: null});
            }
        } else {
            setPushImage({PushImage: null});
        }
    }

    function updateLocation(locationID) {
        const itemLocation =
            locationData.locations.filter(item => item.LocationID == locationID);
        if (itemLocation[0]) {
            itemLocation[0].IsSelected = !itemLocation[0].IsSelected;
        }
        setLocationData({
            locations: sortLocations(locationData.locations),
            selectedCount: locationData.locations.filter(item => item.IsSelected).length,
            loading: false
        })
        setIsSelectedLocations({
            selectedAll: locationData.locations.every(i => i.IsSelected)
        });
        setCountData({sendCount: 0, loading: false});
    }

    function renderFields() {
        if (selectedPushType.PushType === 2) {
            return <>

                {
                    //MealType Start
                }
                <FormControl
                    className={classes.textField}
                    fullWidth
                >
                    <InputLabel htmlFor="meal-types-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.MEAL_TYPE_FIELD.LABEL"/>
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps("mealType")}
                        disabled={meals.length === 0}
                        onChange={(event) => {
                            formik.setFieldValue("mealType", event.target.value)
                            locationDataUpdate(selectedPushType.PushType);
                        }}
                        input={<FilledInput name="item" id="item-select"/>}
                    >
                        {
                            meals.map(item => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {renderErrors("mealType")}
                {
                    //MealType End
                }


                {
                    //Supplier Start
                }
                <FormControl
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                >
                    <InputLabel htmlFor="locations-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.SUPPLIER_FIELD.LABEL"/>
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps("supplier")}
                        onChange={event => {
                            formik.setFieldValue("supplier", event.target.value)
                            formik.setFieldValue("items", [])
                            setItemData({loading: true})
                            setCountData({sendCount: 0, loading: false});
                            const cancelToken = axios.CancelToken.source()
                            fetchItems(event.target.value, cancelToken)
                            return () => cancelToken.cancel()
                        }}

                        input={<FilledInput name="supplier" id="supplier-select"/>}
                    >
                        {
                            supplierData.suppliers.map(supplier => (
                                <MenuItem
                                    key={supplier.SupplierID}
                                    value={supplier.SupplierID}
                                >
                                    {supplier.Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {renderErrors("supplier")}
                {
                    //Supplier End
                }
                {
                    //Item Start
                }
                <FormControl
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                >
                    <InputLabel htmlFor="locations-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ITEM_FIELD.LABEL"/>
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps("item")}
                        disabled={itemData.items.length === 0}
                        onChange={event => {
                            formik.setFieldValue("image", null)
                            formik.setFieldValue("item", event.target.value)
                            formik.setFieldValue("locations", [])
                            setLocationData({loading: true, selectedCount: 0, locations: []})
                            setIsSelectedLocations({
                                selectedAll: false
                            });
                            setCountData({sendCount: 0, loading: false});
                            defaultImageSet(event.target.value);
                            const cancelToken = axios.CancelToken.source()
                            fetchMealAndItemLocations(event.target.value, cancelToken)
                            return () => cancelToken.cancel()
                        }}
                        input={<FilledInput name="item" id="item-select"/>}
                    >
                        {
                            itemData.items.map(item => (
                                <MenuItem
                                    key={item.ItemID}
                                    value={item.ItemID}
                                >
                                    {item.Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {renderErrors("item")}
                {
                    //Item End
                }


                {
                    //Location Start
                }

                <LocationList
                    locationsData={locationData}
                    selectedAll={isSelectedLocations.selectedAll}
                    handleSelection={handleLocationSelection}
                    setSelected={updateLocation}/>
                {
                    //Location End
                }

            </>;
        }
        if (selectedPushType.PushType === 3) {
            return <>
                {
                    //MealType Start
                }
                <FormControl
                    className={classes.textField}
                    fullWidth
                >
                    <InputLabel htmlFor="meal-types-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.MEAL_TYPE_FIELD.LABEL"/>
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps("mealType")}
                        disabled={meals.length === 0}
                        onChange={(event) => {
                            formik.setFieldValue("mealType", event.target.value)
                            locationDataUpdate(selectedPushType.PushType);
                        }}
                        input={<FilledInput name="item" id="item-select"/>}
                    >
                        {
                            meals.map(item => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {renderErrors("mealType")}
                {
                    //MealType End
                }


                {
                    //Supplier Start
                }
                <FormControl
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                >
                    <InputLabel htmlFor="locations-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.SUPPLIER_FIELD.LABEL"/>
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps("supplier")}
                        onChange={event => {
                            formik.setFieldValue("supplier", event.target.value)
                            formik.setFieldValue("items", [])
                            setCountData({sendCount: 0, loading: false});
                        }}

                        input={<FilledInput name="supplier" id="supplier-select"/>}
                    >
                        {
                            supplierData.suppliers.map(supplier => (
                                <MenuItem
                                    key={supplier.SupplierID}
                                    value={supplier.SupplierID}
                                >
                                    {supplier.Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {renderErrors("supplier")}
                {
                    //Supplier End
                }

                {
                    //Location Start
                }

                <LocationList
                    locationsData={locationData}
                    selectedAll={isSelectedLocations.selectedAll}
                    handleSelection={handleLocationSelection}
                    setSelected={updateLocation}/>
                {
                    //Location End
                }

            </>;
        }
        return  (<div style={{minHeight: 136.88}}>


            {
                //Customer Start
            }
            <FormControl
                variant="filled"
                className={classes.textField}
                fullWidth
            >
                <InputLabel htmlFor="customers-select">
                    <FormattedMessage id="ADMIN_USERS.CREATE_FORM.CUSTOMER_FIELD.LABEL"/>
                </InputLabel>
                <Select
                    {...formik.getFieldProps("customer")}
                    onChange={event => {
                        formik.setFieldValue("customer", event.target.value)
                        const customer = customerData.customers.find(it => it.CustomerID === event.target.value);
                        if (customer) {
                            setDepartmentData({
                                departments: customer?.Departments || []
                            });
                            fetchCustomerLocations(event.target.value, axios.CancelToken.source());
                        } else {
                            locationDataUpdate(selectedPushType.PushType);
                        }
                        setCountData({sendCount: 0, loading: false});
                        formik.setFieldValue('delivery', false)
                        formik.setFieldValue('orderDate', new Date());
                        formik.setFieldValue('ordered', null)
                    }}
                    input={<FilledInput name="customer" id="customer-select"/>}
                >
                    <MenuItem
                        key={undefined}
                        value={undefined}
                    >
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.CUSTOMER_FIELD.ALL"/>
                    </MenuItem>
                    {
                        customerData.customers.map(customer => (
                            <MenuItem
                                key={customer.CustomerID}
                                value={customer.CustomerID}
                            >
                                {customer.Name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {renderErrors("customer")}
            {
                //Customer End
            }
            {
                //Department Start
            }
            {
                formik.getFieldProps('customer')?.value &&
                (
                    <>
                        <FormControl
                            variant="filled"
                            className={classes.textField}
                            fullWidth
                        >
                            <InputLabel htmlFor="departments-select">
                                <FormattedMessage id="ADMIN_USERS.CREATE_FORM.DEPARTMENT_FIELD.LABEL"/>
                            </InputLabel>
                            <Select
                                {...formik.getFieldProps("department")}
                                onChange={event => {
                                    formik.setFieldValue("department", event.target.value)
                                    setCountData({sendCount: 0, loading: false});
                                }}
                                input={<FilledInput name="department" id="department-select"/>}
                            >
                                <MenuItem
                                    key={undefined}
                                    value={undefined}
                                >
                                    <FormattedMessage id="ADMIN_USERS.CREATE_FORM.DEPARTMENT_FIELD.ALL"/>
                                </MenuItem>
                                {
                                    departmentData.departments.map(department => (
                                        <MenuItem
                                            key={department.DepartmentID}
                                            value={department.DepartmentID}
                                        >
                                            {department.Name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        {renderErrors("department")}
                    </>
                )
            }
            {
                //Department End
            }
            {
                //Location Start
            }
            <LocationList
                selectedAll={isSelectedLocations.selectedAll}
                locationsData={locationData}
                handleSelection={handleLocationSelection}
                setSelected={updateLocation}/>
            {
                //Location End
            }
            {
                //Delivery Start
            }
            {
                <div>
                    <Checkbox
                        onChange={(event) => {
                            formik.setFieldValue('delivery', !formik.getFieldProps('delivery')?.value)
                            if (!formik.getFieldProps('delivery')?.value) {
                                formik.setFieldValue('orderDate', new Date());
                                formik.setFieldValue('ordered', null)
                            }
                        }}
                        value={formik.getFieldProps('delivery')?.value}
                        name="delivery"
                        color="secondary"
                    />
                    <span>
                            {
                                intl.formatMessage({
                                    id: "ADMIN_DETAIL.LABELS.DELIVERY"
                                })
                            }
                          </span>
                </div>
            }
            {
                !!formik.getFieldProps('delivery')?.value && (
                    <div className='common-container'>
                        <div>
                            <Checkbox
                                onChange={(event) => {
                                    formik.setFieldValue('ordered', !formik.getFieldProps('ordered')?.value)
                                }}
                                value={formik.getFieldProps('ordered')?.value}
                                name="ordered"
                                color="secondary"
                            />
                            <span>
                            {
                                intl.formatMessage({
                                    id: "ADMIN_DETAIL.LABELS.DELIVERY_ORDERED"
                                })
                            }
                          </span>
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                            <DatePicker
                                autoOk
                                format="dd/MM/yyyy"
                                minDate={today}
                                value={formik.values.orderDate}
                                onChange={value => {
                                    formik.setFieldValue("orderDate", value)
                                }}
                                label={intl.formatMessage({
                                    id: "DATE_FIELD.LABEL"
                                })}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                )
            }
            {
                //Delivery End
            }
        </div>)

    }

    function renderEmojiAdornment(fieldName) {
        return (
            <InputAdornment position="end">
                <IconButton
                    onClick={() => handleClickShowEmojis(fieldName)}
                    edge="end"
                >
                    {showEmojiPickerOnField === fieldName ? <EmojiEmotions/> : <TagFaces/>}
                </IconButton>
            </InputAdornment>
        )
    }

    function renderEmojiPicker(fieldName) {
        return showEmojiPickerOnField === fieldName ? (
            <div className={classes.emojiPicker}>
                <Picker
                    pickerStyle={{width: "100%"}}
                    native
                    onEmojiClick={(event, emojiObject) => formik.setFieldValue(fieldName, formik.values[fieldName] + emojiObject.emoji)}
                />
            </div>
        ) : null
    }

    function handleClickShowEmojis(field) {
        setShowEmojiPickerOnField(field === showEmojiPickerOnField ? "" : field)
    }

    function handlePushReportSubmit() {
        history.push('/reports-push');
    }

    return (
        <>
            <ConfirmationQuestionDialog
                show={confirmationQuestionState.show}
                onClose={handleCloseConfirmationQuestionPanel}
                sendRequest={handleSendSubmit}
                questionData={{
                    Question: intl.formatMessage({
                        id: "ADMIN_DETAIL.LABELS.PUSH_CONFIRMATION"
                    }, {X: countData.sendCount})
                }}
                extraAction={handlePushReportSubmit}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                <div>
                    <Typography
                        variant="h3"
                        id="userAdminTitle"
                        style={{textAlign: "right"}}
                    >
                        <FormattedMessage id="ADMIN_PUSH.TITLE"/>
                    </Typography>
                    {
                        (supplierData.loading || itemData.loading ||
                            locationData.loading || customerData.loading) && <DishLoader/>
                    }
                    {
                        !(supplierData.loading || itemData.loading || locationData.loading) &&
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card card-custom flex-row justify-content-center gutter-b">
                                        <div className="card-body flex-grow-0 full-width">

                                            <FormControl
                                                fullWidth={true}
                                                className={classes.textField}
                                            >
                                                <InputLabel htmlFor="pushType-select">
                                                    <FormattedMessage
                                                        id="ADMIN_USERS.CREATE_FORM.PUSH_TYPE_FIELD.LABEL"/>
                                                </InputLabel>
                                                <Select
                                                    {...formik.getFieldProps("pushType")}
                                                    onChange={event => {
                                                        setPushType({PushType: event.target.value})
                                                        formik.setFieldValue("pushType", event.target.value)
                                                        locationDataUpdate(event.target.value);
                                                    }}
                                                    input={<FilledInput name="pushType" id="pushType-select"/>}
                                                >
                                                    {pushTypes.map(pushType => (
                                                        <MenuItem
                                                            key={pushType.id}
                                                            value={pushType.id}
                                                        >
                                                            {pushType.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            {
                                                // Role
                                            }


                                            {
                                                // Role end
                                            }
                                            <FormControl
                                                variant="filled"
                                                fullWidth={true}
                                                className={classes.textField}
                                            >
                                                <InputLabel htmlFor="role-select">
                                                    <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ROLE_FIELD.LABEL"/>
                                                </InputLabel>
                                                <Select
                                                    {...formik.getFieldProps("role")}
                                                    onChange={event => {
                                                        formik.setFieldValue("role", event.target.value)
                                                        setCountData({sendCount: 0, loading: false});
                                                    }}
                                                    input={<FilledInput name="role" id="role-select"/>}
                                                >
                                                    <MenuItem value={null}>
                                                        <FormattedMessage id="FILTER.COMMON.ALL"/>
                                                    </MenuItem>
                                                    {roles.map(role => (
                                                        <MenuItem
                                                            key={role}
                                                            value={role}
                                                        >
                                                            <FormattedMessage id={getRoleID(role)}/>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {
                                                (
                                                    renderFields()
                                                )
                                            }

                                            <div className='mt-5'>
                                                <div className={classes.totalItems}>
                                                    <button
                                                        type="button"
                                                        onClick={handleCalculationSubmit}
                                                        disabled={!locationData.selectedCount}
                                                        className={`btn btn-primary font-weight-bold mr-2`}
                                                    >
                                                        <i className="fa fa-eye"/>
                                                        {
                                                            intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.CALCULATE_PUSH"
                                                            })
                                                        }
                                                    </button>
                                                    <div>
                                                        {countData.count !== undefined ? countData.count : ''}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body">

                                            <FormControl
                                                variant="outlined"
                                                className={classes.textField}
                                                fullWidth
                                            >
                                                <TextField
                                                    id="name"
                                                    name="name"
                                                    label={intl.formatMessage({
                                                        id: "ADMIN_DETAIL.LABELS.NAME"
                                                    })}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    {...formik.getFieldProps("name")}
                                                    error={checkForError("name")}
                                                    inputProps={{
                                                        className: classes.textRight
                                                    }}
                                                />
                                            </FormControl>
                                            {renderErrors("name")}


                                            <FormControl
                                                variant="outlined"
                                                className={classes.textField}
                                                fullWidth
                                            >
                                                <TextField
                                                    id="title"
                                                    name="title"
                                                    label={intl.formatMessage({
                                                        id: "ADMIN_DETAIL.LABELS.TITLE"
                                                    })}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: renderEmojiAdornment("title")
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    {...formik.getFieldProps("title")}
                                                    error={checkForError("title")}
                                                    inputProps={{
                                                        className: classes.textRight
                                                    }}
                                                />
                                                {renderEmojiPicker("title")}
                                            </FormControl>
                                            {renderErrors("title")}


                                            <FormControl
                                                variant="outlined"
                                                className={classes.textField}
                                                fullWidth
                                            >
                                                <TextField
                                                    id="subTitle"
                                                    name="subTitle"
                                                    label={intl.formatMessage({
                                                        id: "ADMIN_DETAIL.LABELS.SUBTITLE"
                                                    })}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: renderEmojiAdornment("subTitle")
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    {...formik.getFieldProps("subTitle")}
                                                    error={checkForError("subTitle")}
                                                    inputProps={{
                                                        className: classes.textRight
                                                    }}
                                                />
                                                {renderEmojiPicker("subTitle")}
                                            </FormControl>
                                            {renderErrors("subTitle")}


                                            <FormControl
                                                variant="outlined"
                                                className={classes.textField}
                                                fullWidth
                                            >
                                                <TextField
                                                    id="message"
                                                    name="message"
                                                    label={intl.formatMessage({
                                                        id: "ADMIN_DETAIL.LABELS.MESSAGE"
                                                    })}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: renderEmojiAdornment("message")
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    {...formik.getFieldProps("message")}
                                                    error={checkForError("message")}
                                                    inputProps={{
                                                        className: classes.textRight
                                                    }}
                                                />
                                                {renderEmojiPicker("message")}
                                            </FormControl>
                                            {renderErrors("message")}


                                            <div className={classes.textField}>
                                                <div>
                                                    {
                                                        globalPushImage.PushImage &&
                                                        <Button variant="contained" component="label"
                                                                className={classes.optionBtn}
                                                                onClick={() => {
                                                                    formik.setFieldValue("pushImage", null)
                                                                    setGlobalPushImage({PushImage: null})
                                                                }}>
                                                            {intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.REMOVE_PUSH_IMAGE"
                                                            })}
                                                        </Button>
                                                    }

                                                    <Button variant="contained" component="label">
                                                        {intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.PUSH_IMAGE"
                                                        })}
                                                        <input
                                                            id="image-file"
                                                            name="pushImage"
                                                            type="file"
                                                            accept="image/png"
                                                            onChange={event => {
                                                                if (event.currentTarget && event.currentTarget.files[0]) {
                                                                    formik.setFieldValue("pushImage", event.currentTarget.files[0])
                                                                    var reader = new FileReader();
                                                                    reader.onloadend = (readerEvt) => {
                                                                        var binaryString = readerEvt.target.result;
                                                                        setGlobalPushImage({PushImage: binaryString});
                                                                    };
                                                                    reader.readAsDataURL(event.currentTarget.files[0]);
                                                                }
                                                            }}
                                                            hidden
                                                        />
                                                    </Button>
                                                </div>

                                                {
                                                    globalPushImage.PushImage &&
                                                    <div>
                                                        <img src={globalPushImage.PushImage}
                                                             className={classes.pushImage}/>
                                                    </div>
                                                }
                                            </div>


                                            <FormControl
                                                fullWidth={true}
                                                className={classes.textField}
                                            >
                                                <InputLabel htmlFor="role-select">
                                                    <FormattedMessage
                                                        id="ADMIN_USERS.CREATE_FORM.ACTION_TYPE_FIELD.LABEL"/>
                                                </InputLabel>
                                                <Select
                                                    {...formik.getFieldProps("actionType")}
                                                    onChange={event => {
                                                        formik.setFieldValue("actionType", event.target.value);
                                                        defaultImageSet(formik.values.item);
                                                        if (event.target.value !== 2) {
                                                            formik.setFieldValue('url', '')
                                                        }
                                                        if (event.target.value !== 3) {
                                                            setPushImage({PushImage: null})
                                                        }
                                                    }}
                                                    input={<FilledInput name="actionType" id="actionType-select"/>}
                                                >
                                                    {actionTypes.map(actionType => (
                                                        <MenuItem
                                                            key={actionType.id}
                                                            value={actionType.id}
                                                        >
                                                            {actionType.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            {
                                                formik.values.actionType === 2 &&
                                                <>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.textField}
                                                        fullWidth
                                                    >
                                                        <TextField
                                                            id="url"
                                                            name="url"
                                                            label={intl.formatMessage({
                                                                id: "ADMIN_DETAIL.LABELS.URL"
                                                            })}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            {...formik.getFieldProps("url")}
                                                            error={checkForError("url")}
                                                            inputProps={{
                                                                className: classes.textRight
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {renderErrors("url")}
                                                </>
                                            }

                                            {
                                                formik.values.actionType === 3 &&
                                                <div className={classes.textField}>
                                                    <Button variant="contained" component="label">
                                                        ...
                                                        <input
                                                            id="image-file"
                                                            name="image"
                                                            type="file"
                                                            accept="image/png"
                                                            onChange={event => {
                                                                if (event.currentTarget && event.currentTarget.files[0]) {
                                                                    formik.setFieldValue("image", event.currentTarget.files[0])
                                                                    var reader = new FileReader();
                                                                    reader.onloadend = (readerEvt) => {
                                                                        var binaryString = readerEvt.target.result;
                                                                        setPushImage({PushImage: binaryString});
                                                                    };
                                                                    reader.readAsDataURL(event.currentTarget.files[0]);
                                                                }
                                                            }}
                                                            hidden
                                                        />
                                                    </Button>
                                                    {
                                                        pushImage.PushImage &&
                                                        <div>
                                                            <img src={pushImage.PushImage}
                                                                 className={classes.pushImage}/>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            <FormControl
                                                fullWidth={true}
                                                className={classes.textField}
                                            >
                                                <InputLabel htmlFor="role-select">
                                                    <FormattedMessage
                                                        id="ADMIN_USERS.CREATE_FORM.SEND_TYPE_FIELD.LABEL"/>
                                                </InputLabel>
                                                <Select
                                                    {...formik.getFieldProps("sendType")}
                                                    onChange={event => {
                                                        formik.setFieldValue("sendType", event.target.value)
                                                        if (formik.values.sendType !== 2) {

                                                        }
                                                    }}
                                                    input={<FilledInput name="sendType" id="sendType-select"/>}
                                                >
                                                    {sendTypes.map(sendType => (
                                                        <MenuItem
                                                            key={sendType.id}
                                                            value={sendType.id}
                                                        >
                                                            {sendType.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {
                                                formik.values.sendType === 2 &&
                                                <>

                                                    <div className={classes.timePicker}>
                                                        <TimePicker
                                                            autoOk
                                                            ampm={false}
                                                            value={formik.values.sendTime}
                                                            className={classes.timePickerField}
                                                            onChange={value => {
                                                                formik.setFieldValue("sendTime", value)
                                                            }}
                                                            clearable
                                                            TextFieldComponent={({
                                                                                     helperText,
                                                                                     InputProps,
                                                                                     ...props
                                                                                 }) => (
                                                                <FormControl
                                                                    variant="filled"
                                                                    className={classes.textField}
                                                                    fullWidth
                                                                >
                                                                    <TextField
                                                                        name="helperText"
                                                                        margin="normal"
                                                                        variant="filled"
                                                                        fullWidth
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        inputProps={{
                                                                            className: classes.containerInput
                                                                        }}
                                                                        hiddenLabel
                                                                        {...props}
                                                                        error={checkForError("sendTime")}
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                        <div className={classes.timePicker}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={he}>
                                                                <DatePicker
                                                                    autoOk
                                                                    format="dd/MM/yyyy"
                                                                    minDate={today}
                                                                    value={formik.values.sendDate}
                                                                    onChange={value => {
                                                                        formik.setFieldValue("sendDate", value)
                                                                    }}
                                                                    label={intl.formatMessage({
                                                                        id: "DATE_FIELD.LABEL"
                                                                    })}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>
                                                    </div>


                                                </>
                                            }


                                        </div>
                                    </div>
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body">

                                            <div className={classes.pushMessageTitle}>
                                                <b>
                                                    {
                                                        intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.GROUP_MESSAGE"
                                                        })
                                                    }
                                                </b>
                                            </div>

                                            <div className={`mt-5`}>
                                                <button
                                                    type="button"
                                                    onClick={handleSendConfirmationSubmit}
                                                    disabled={!locationData.selectedCount || !formik.values.message}
                                                    className={`btn btn-primary font-weight-bold mr-2`}
                                                >
                                                    {
                                                        intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.SEND_PUSH"
                                                        })
                                                    }
                                                </button>
                                            </div>

                                            <br/>

                                            <div className={classes.pushMessageTitle}>
                                                <b>
                                                    {
                                                        intl.formatMessage({
                                                            id: "ADMIN_DETAIL.LABELS.TEST_MESSAGE"
                                                        })
                                                    }
                                                </b>
                                            </div>

                                            <FormControl
                                                variant="outlined"
                                                className={classes.textField}
                                                fullWidth
                                            >
                                                <TextField
                                                    id="source"
                                                    name="source"
                                                    label={intl.formatMessage({
                                                        id: "ADMIN_DETAIL.LABELS.EMAIL_OR_PHONE"
                                                    })}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    {...formik.getFieldProps("source")}
                                                    error={checkForError("source")}
                                                    inputProps={{
                                                        className: classes.textRight
                                                    }}
                                                />
                                            </FormControl>

                                            <button
                                                type="button"
                                                onClick={handleTestSubmit}
                                                disabled={!formik.values.message}
                                                className={`btn btn-primary font-weight-bold mr-2`}
                                            >
                                                <i className="fa fa-eye"/>
                                                {
                                                    intl.formatMessage({
                                                        id: "ADMIN_DETAIL.LABELS.SEND_PUSH_TEST"
                                                    })
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </MuiPickersUtilsProvider>
        </>
    )
}

export default withRouter(injectIntl(PushAdminPage))
