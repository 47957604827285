/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  bigForm: {
    minWidth: 1200
  },
  smallForm: {
    maxWidth: 300
  },
  conditionContainer: {
    display: 'flex'
  },
  label: {
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  },
  dayContainer: {
    padding: '0.5rem 0',
    boxSizing: 'border-box'
  },
  chooseIconButton: {
    minWidth: "auto"
  },
  locationContrainer: {
    maxHeight: '300px',
    overflowY: 'auto'
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
  },
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1, 0)
  },
  error: { position: "absolute", bottom: -8, left: 10, color: "#F018A6" },
  textRight: {
    textAlign: "left"
  },
  descriptionLine: {
    padding: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid lightgray"
  },
  comboItemContainer: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  largeDialog: {
    minWidth: 500
  },
  formControl: {
    width: '120px'
  },
  imgContainer: {
    width: '120px'
  }
}))
