/* eslint-disable no-restricted-imports */
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import moment from "moment"
import React, { useState } from "react"     
import { FormattedMessage, injectIntl } from "react-intl"
import { Link } from "react-router-dom"
import DishLoader from "../../Common/DishLoader"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"
import { getSorting, stableSort } from "../../Common/tableSortingFunctions"

export function formatDateForApi(date) {
    return moment(date).format("YYYY-MM-DD")
}
function BudgetUsageList({ userRole, reportsData, headRows, intl }) {
    const classes = useStyles()
    const rows = reportsData.reports
    const [sortBy, setSortBy] = useState("FirstName")
    const [orderBy, setOrderBy] = useState("asc")

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    const sum = Math.round(rows.reduce((s, it) => s += it.Sum, 0) * 100) / 100;
    const walletTransfer = Math.round(rows.reduce((s, it) => s += it.WalletTransfer, 0) * 100) / 100;
    const totalMealSum = Math.round(rows.reduce((s, it) => s += it.TotalMealSum, 0) * 100) / 100;
    const totalRefundSum = Math.round(rows.reduce((s, it) => s += it.RefundSum, 0) * 100) / 100;
    const mealSum = Math.round(rows.reduce((s, it) => s += it.MealSum, 0) * 100) / 100;
    const storeSum = Math.round(rows.reduce((s, it) => s += it.StoreSum, 0) * 100) / 100;;

    return (
        <Paper className={classes.paper}>
            {reportsData.isLoading && <DishLoader centered />}
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={`budget-usage-row-${index}`} >
                                    <TableCell component="th" scope="row" align="left">
                                        {userRole === "Admin" ? (
                                            <Link
                                                to={`/user/${row.UserID
                                                    }/order-history?fromDate=${formatDateForApi(
                                                        reportsData.from
                                                    )}&toDate=${formatDateForApi(reportsData.to)}&tab=budgets`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {row.FirstName} {row.LastName}
                                            </Link>
                                        ) : (
                                            `${row.FirstName} ${row.LastName}`
                                        )}
                                    </TableCell>

                                    <TableCell>{row.ExternalID}</TableCell>
                                    <TableCell>{row.DepartmentName}</TableCell>
                                    <TableCell>{row.Sum}₪</TableCell>
                                    <TableCell>{row.WalletTransfer}₪</TableCell>
                                    <TableCell>{row.MealSum}₪</TableCell>
                                    <TableCell>{row.StoreSum}₪</TableCell>
                                    <TableCell>{row.TotalMealSum}₪</TableCell>
                                    <TableCell>{row.RefundSum}₪</TableCell>
                                </TableRow>
                            )
                        })}
                        {
                            rows && rows.length && (
                                <TableRow hover role="checkbox" tabIndex={-1} key={`budget-usage-row-sumarry`} className="summary-line">
                                    <TableCell component="th" scope="row" align="left"><FormattedMessage id="REPORT.TOTAL" /></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{sum}₪</TableCell>
                                    <TableCell>{walletTransfer}₪</TableCell>
                                    <TableCell>{mealSum}₪</TableCell>
                                    <TableCell>{storeSum}₪</TableCell>
                                    <TableCell>{totalMealSum}₪</TableCell>
                                    <TableCell>{totalRefundSum}₪</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default injectIntl(BudgetUsageList)
