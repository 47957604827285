/* eslint-disable no-restricted-imports */
import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import FilledInput from "@material-ui/core/FilledInput"
import InputMask from "react-input-mask"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from "react-intl"
import {
  getRoleID,
  customerUserRoles,
  supplierUserRoles
} from "./rolesTranslation"
import { useStyles } from "../Common/_styles/formDialogStyles"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../../redux/snackbar/snackbarHandlers"

function CreateUserFormDialog({
  show,
  closeDialog,
  intl,
  variant,
  submitData,
  allCustomers,
  allSuppliers,
  availableUserRoles
}) {
  const dispatch = useDispatch()

  const classes = useStyles()
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    customer: variant === "customer" ? allCustomers[0].CustomerID : "",
    locations: [],
    department: "",
    supplier: ""
  }

  function handleSumbit(userID) {
    closeDialog(userID)
  }

  function handleClose() {
    closeDialog(null)
  }

  const firstNameValidationSchema = Yup.string()
    .min(
      2,
      intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 2 })
    )
    .max(
      50,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
        { max: 50 }
      )
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const lastNameValidationSchema = Yup.string()
    .min(
      2,
      intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 2 })
    )
    .max(
      50,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
        { max: 50 }
      )
    )
    .required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )

  const emailValidationSchema = Yup.string()
    .email(
      intl.formatMessage({
        id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT"
      })
    )
    .min(
      2,
      intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_FIELD" }, { min: 2 })
    )
    .max(
      50,
      intl.formatMessage(
        { id: "AUTH.VALIDATION.MAX_LENGTH_FIELD" },
        { max: 50 }
      )
    )
    .when("phone", {
      is: phone => {
        return !phone
      },
      then: Yup.string().required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      )
    })

  const phoneValidationSchema = Yup.string()
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      intl.formatMessage({
        id: "AUTH.VALIDATION.WRONG_PHONE_FORMAT"
      })
    )
    .when("email", {
      is: email => {
        return !email
      },
      then: Yup.string().required(
        intl.formatMessage({
          id: "VALIDATION.REQUIRED_FIELD"
        })
      )
    })

  const roleValidationSchema = Yup.string().required(
    intl.formatMessage({
      id: "VALIDATION.REQUIRED_FIELD"
    })
  )

  const customerValidationSchema = Yup.string().when("role", {
    is: role => {
      return variant === "admin" && customerUserRoles.includes(role)
    },
    then: Yup.string().required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )
  })

  const supplierValidationSchema = Yup.string().when("role", {
    is: role => {
      return variant === "admin" && supplierUserRoles.includes(role)
    },
    then: Yup.string().required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )
  })

  const locationsValidationSchema = Yup.array().when("role", {
    is: role => {
      return (
        ["admin", "customer"].includes(variant) &&
        customerUserRoles.includes(role)
      )
    },
    then: Yup.array().test(
      "NotEmpty",
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      }),
      value => {
        return value.length > 0
      }
    )
  })

  const departmentValidationSchema = Yup.string().when("role", {
    is: role => {
      return (
        ["admin", "customer"].includes(variant) &&
        customerUserRoles.includes(role)
      )
    },
    then: Yup.string().required(
      intl.formatMessage({
        id: "VALIDATION.REQUIRED_FIELD"
      })
    )
  })

  const validationSchema = Yup.object().shape(
    {
      firstName: firstNameValidationSchema,
      lastName: lastNameValidationSchema,
      email: emailValidationSchema,
      phone: phoneValidationSchema,
      role: roleValidationSchema,
      customer: customerValidationSchema,
      locations: locationsValidationSchema,
      department: departmentValidationSchema,
      supplier: supplierValidationSchema
    },
    [
      "email",
      "phone",
      "role",
      "customer",
      "locations",
      "department",
      "supplier",
      "firstName",
      "lastName"
    ]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
      const {
        email,
        phone,
        role,
        invoice,
        externalID,
        customer,
        locations,
        department,
        supplier,
        firstName,
        lastName
      } = values
      const newUser = {
        "First Name": firstName,
        "Last Name": lastName,
        Email: email,
        Mobile: phone,
        Role: role,
        InvoiceName: invoice,
        ExternalID: externalID
      }
      if (customerUserRoles.includes(role)) {
        newUser.CustomerID = customer
        newUser.Locations = allCustomers
          .find(el => el.CustomerID === customer)
          .Locations.filter(location => locations.includes(location.LocationID))
        newUser.DepartmentID = department

        newUser.SupplierID = null
      } else if (supplierUserRoles.includes(role)) {
        newUser.SupplierID = supplier

        newUser.CustomerID = null
        newUser.Locations = []
        newUser.DepartmentID = null
      } else {
        newUser.SupplierID = null
        newUser.CustomerID = null
        newUser.Locations = []
        newUser.DepartmentID = null
      }
      setSubmitting(true)
      submitData(newUser)
        .then(({ data }) => {
          setSubmitting(false)
          handleSumbit(data.UserID)
          resetForm()
        })
        .catch(error => {
          setSubmitting(false)
          if (error.response && error.response.status === 409) {
            const { emailTaken, mobileTaken } = error.response.data

            if (emailTaken) {
              setFieldError(
                "email",
                intl.formatMessage({
                  id: "ADMIN_USERS.VALIDATION.EMAIL_USED"
                })
              )
            }
            if (mobileTaken) {
              setFieldError(
                "phone",
                intl.formatMessage({
                  id: "ADMIN_USERS.VALIDATION.MOBILE_USED"
                })
              )
            }
          } else {
            handleApiError(
              dispatch,
              error,
              intl.formatMessage({
                id: "API.ERROR.FAILED_TO_CREATE_USER"
              })
            )
          }
        })
    }
  })

  function checkForError(fieldName) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true
    }
    return false
  }

  function renderErrors(fieldName) {
    return checkForError(fieldName) ? (
      <span style={{ color: "#F018A6" }}>{formik.errors[fieldName]}</span>
    ) : null
  }

  function renderLocationAndDepartmentFields() {
    return formik.values.customer !== "" ? (
      <>
        <FormControl
          variant="filled"
          className={classes.textField}
          fullWidth
          error={checkForError("locations")}
        >
          <InputLabel htmlFor="locations-select">
            <FormattedMessage id="ADMIN_USERS.CREATE_FORM.LOCATION_FIELD.LABEL" />
          </InputLabel>
          <Select
            {...formik.getFieldProps("locations")}
            multiple
            input={<FilledInput name="locations" id="locations-select" />}
          >
            {formik.values.customer &&
              allCustomers
                .find(
                  customer => customer.CustomerID === formik.values.customer
                )
                .Locations.map(location => (
                  <MenuItem
                    key={location.LocationID}
                    value={location.LocationID}
                  >
                    {location.Name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        {renderErrors("locations")}
        <FormControl
          variant="filled"
          className={classes.textField}
          fullWidth
          error={checkForError("department")}
        >
          <InputLabel htmlFor="department-select">
            <FormattedMessage id="ADMIN_USERS.CREATE_FORM.DEPARTMENT_FIELD.LABEL" />
          </InputLabel>
          <Select
            {...formik.getFieldProps("department")}
            input={<FilledInput name="department" id="department-select" />}
          >
            {formik.values.customer &&
              allCustomers
                .find(
                  customer => customer.CustomerID === formik.values.customer
                )
                .Departments.map(department => (
                  <MenuItem
                    key={department.DepartmentID}
                    value={department.DepartmentID}
                  >
                    {department.Name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        {renderErrors("department")}
      </>
    ) : (
      <div style={{ height: 136.88 }} />
    )
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            <FormattedMessage id="ADMIN_USERS.CREATE_FORM.TITLE" />
          </h3>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <DialogContent>
            <TextField
              id="first-name"
              name="firstName"
              label={intl.formatMessage({
                id: "ADMIN_USERS.CREATE_FORM.FIRST_NAME_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("firstName")}
              error={checkForError("firstName")}
            />
            {renderErrors("firstName")}
            <TextField
              id="last-name"
              name="lastName"
              label={intl.formatMessage({
                id: "ADMIN_USERS.CREATE_FORM.LAST_NAME_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("lastName")}
              error={checkForError("lastName")}
            />
            {renderErrors("lastName")}
            <InputMask
              name="phone"
              mask="999-9999999"
              {...formik.getFieldProps("phone")}
            >
              {() => (
                <TextField
                  id="phone"
                  label={intl.formatMessage({
                    id: "ADMIN_USERS.CREATE_FORM.MOBILE_FIELD.LABEL"
                  })}
                  className={classes.textField}
                  margin="normal"
                  variant="filled"
                  fullWidth
                  error={checkForError("phone")}
                  inputProps={{
                    className: classes.textRight
                  }}
                  dir="ltr"
                />
              )}
            </InputMask>
            {renderErrors("phone")}
            <TextField
              id="email"
              name="email"
              label={intl.formatMessage({
                id: "ADMIN_USERS.CREATE_FORM.EMAIL_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("email")}
              error={checkForError("email")}
              inputProps={{
                className: classes.textRight
              }}
              dir="ltr"
            />
            {renderErrors("email")}
            <TextField
              id="invoice"
              name="invoice"
              label={intl.formatMessage({
                id: "ADMIN_USERS.CREATE_FORM.INVOICE_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("invoice")}
              error={checkForError("invoice")}
              inputProps={{
                className: classes.textRight
              }}
            />
            {renderErrors("invoice")}

            <TextField
              id="externalID"
              name="externalID"
              label={intl.formatMessage({
                id: "ADMIN_USERS.CREATE_FORM.EXTERNAL_ID_FIELD.LABEL"
              })}
              className={classes.textField}
              margin="normal"
              variant="filled"
              fullWidth
              {...formik.getFieldProps("externalID")}
              error={checkForError("externalID")}
              inputProps={{
                className: classes.textRight
              }}
            />
            {renderErrors("externalID")}


            <FormControl
              variant="filled"
              className={classes.textField}
              fullWidth
              error={checkForError("role")}
            >
              <InputLabel htmlFor="role-select">
                <FormattedMessage id="ADMIN_USERS.CREATE_FORM.ROLE_FIELD.LABEL" />
              </InputLabel>
              <Select
                {...formik.getFieldProps("role")}
                input={<FilledInput name="role" id="role-select" />}
              >
                {availableUserRoles.map(role => (
                  <MenuItem key={role} value={role}>
                    <FormattedMessage id={getRoleID(role)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {renderErrors("role")}
            {variant === "admin" ? (
              <>
                {customerUserRoles.includes(formik.values.role) ? (
                  <>
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("customer")}
                    >
                      <InputLabel htmlFor="role-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.CUSTOMER_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formik.getFieldProps("customer")}
                        onChange={event => {
                          formik.setFieldValue("customer", event.target.value)
                          formik.setFieldValue("locations", [])
                          formik.setFieldValue("department", "")
                        }}
                        input={<FilledInput name="customer" id="role-select" />}
                      >
                        {allCustomers.map(customer => (
                          <MenuItem
                            key={customer.CustomerID}
                            value={customer.CustomerID}
                          >
                            {customer.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("customer")}
                    {renderLocationAndDepartmentFields()}
                  </>
                ) : supplierUserRoles.includes(formik.values.role) ? (
                  <>
                    <FormControl
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      error={checkForError("supplier")}
                    >
                      <InputLabel htmlFor="role-select">
                        <FormattedMessage id="ADMIN_USERS.CREATE_FORM.SUPPLIER_FIELD.LABEL" />
                      </InputLabel>
                      <Select
                        {...formik.getFieldProps("supplier")}
                        input={<FilledInput name="supplier" id="role-select" />}
                      >
                        {allSuppliers.map(supplier => (
                          <MenuItem
                            key={supplier.SupplierID}
                            value={supplier.SupplierID}
                          >
                            {supplier.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {renderErrors("supplier")}
                    <div style={{ height: 136.88 }} />
                  </>
                ) : (
                  <div style={{ height: 205.32 }} />
                )}
              </>
            ) : (
              variant === "customer" && renderLocationAndDepartmentFields()
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={handleClose}
              className={classes.button}
            >
              <FormattedMessage id="ADMIN_USERS.CREATE_FORM.CANCEL_BUTTON" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="secondary"
              className={classes.button}
              disabled={formik.isSubmitting}
            >
              <FormattedMessage id="ADMIN_USERS.CREATE_FORM.SUBMIT_BUTTON" />
              {formik.isSubmitting && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default injectIntl(CreateUserFormDialog)
