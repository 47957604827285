import axios from "axios"

export function getCombos(cancelToken) {
  return axios.get("/Combos", {
    cancelToken
  })
}
export function createCombo(data) {
  return axios.post("/Combos", data)
}
export function editCombo(data) {
  return axios.put(`/Combos/${data.id}`, data)
}
export function deleteCombo(id) {
  return axios.delete(`/Combos/${id}`)
}
export function getCombosOptions(id, itemId) {
  return axios.get(`/Combos/${id}/Items/${itemId}/Options`)
}

